
// Ensure's that each timeline on a page is unique
const timelines = document.querySelectorAll(".timeline");
timelines.forEach((timeline) => {
  const timelineSidebar = timeline.querySelectorAll(
    ".timeline-sidebar .month"
  );
  const yearLabels = timeline.querySelectorAll(".year-label");
  const timelineItems = timeline.querySelectorAll(".timeline-item");
  const timelineDot = timeline.querySelector(".timeline-dot");
  const sidebarNavItems = timeline.querySelectorAll(".sidebar-nav-item");
  const years = Array.from(new Set(Array.from(timeline.querySelectorAll("[data-year]")).map((year) => year.dataset.year)));
  const yearDisplay = timeline.querySelector(".current-year");
  const prevYearArrow = timeline.querySelector(".prev-year-arrow");
  const nextYearArrow = timeline.querySelector(".next-year-arrow");

  let currentYear = null;
  let currentYearIndex = 0; // Start from the latest year (2022)

  // Helper function to move the dot to the corresponding year
  function moveDotToYear(year) {
    const yearElement = timeline.querySelector(
      `[data-target="${year}"] .year-label`
    );

    if (yearElement) {
      const yearPosition = yearElement.getBoundingClientRect();
      const sidebarPosition = document
        .querySelector(".timeline-sidebar")
        .getBoundingClientRect();

      const dotPosition = yearPosition.top - sidebarPosition.top;

      const adjustedDotPosition = dotPosition + yearPosition.height / 2;

      // Update the position of the dot
      timelineDot.style.top = `${adjustedDotPosition}px`;
      timelineDot.classList.remove("opacity-0");
    }
  }

  // Update active sidebar item, month, and move the dot to the corresponding year
  function setActiveSidebar(target) {
    // Reset Tailwind classes for months and years
    timelineSidebar.forEach((el) => {
      el.classList.remove("bg-teal-200", "bg-opacity-50", "rounded-full");
    });
    yearLabels.forEach((year) => {
      year.classList.remove("bg-teal-200", "bg-opacity-50", "rounded-full");
    });

    // Highlight the active month and year
    timelineSidebar.forEach((month) => {
      if (month.dataset.target === target) {
        month.classList.add("bg-teal-200", "bg-opacity-50", "rounded-full");

        // Find the parent year of the active month
        const yearElement = month
          .closest(".year")
          .querySelector(".year-label");
        if (yearElement) {
          yearElement.classList.add(
            "bg-teal-200",
            "bg-opacity-50",
            "rounded-full"
          );

          // Only move the dot if the year has changed
          const newYear = yearElement.parentElement.dataset.target;

          if (currentYear !== newYear) {
            currentYear = newYear; // Update current year
            moveDotToYear(newYear); // Move the dot to the new year
          }
        }
      }
    });
  }

  // Scroll to section with offset when clicking on sidebar items
  sidebarNavItems.forEach((item) => {
    item.addEventListener("click", (e) => {
      const target = e.target.dataset.target;
      const targetElement = timeline.querySelector(`[data-timeline-entry-id='${target}']`);

      targetElement.scrollIntoView({
        behavior: "smooth",
        block: "center"
      });

      setActiveSidebar(target); // Set the active sidebar item
    });
  });

  const fadeElements = timeline.querySelectorAll(".timeline-content .timeline-item");


  // Observer for timeline items with a higher threshold
  const timelineObserver = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const sectionId = entry.target.getAttribute('data-timeline-entry-id');
          console.log(`Timeline item in view: ${sectionId}`);
          setActiveSidebar(sectionId);
        }
      });
    },
    { threshold: 0, rootMargin: "0px 0px -50% 0px" }
  );

  // Observer for fade elements with a lower threshold
  const fadeObserver = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("fade-in-visible");
        }
      });
    },
    { threshold: 0.5 }
  );

  const fadeOutObserver = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          entry.target.classList.remove("fade-in-visible");
        }
      });
    },
    { threshold: 0.1 }
  );

  // Apply observers to respective elements
  timelineItems.forEach((item) => timelineObserver.observe(item));
  fadeElements.forEach((element) => fadeObserver.observe(element));
  fadeElements.forEach((element) => fadeOutObserver.observe(element));

  const timelineImageSwipers = timeline.querySelectorAll(
    ".timeline-image-swiper"
  );
  timelineImageSwipers.forEach((timelineImageSwiper) => {
    new Swiper(timelineImageSwiper, {
      //effect: "fade",
      navigation: {
        nextEl: timelineImageSwiper.querySelector(".timeline-image-swiper__arrows .swiper-button-next"),
        prevEl: timelineImageSwiper.querySelector(".timeline-image-swiper__arrows .swiper-button-prev"),
      },
      loop: false,
    });
  });

  // Initialize Swiper for the mobile timeline (main content carousel)
  const mainSwiperContainer = timeline.querySelector(".swiper-container");

  const renderedMonthsByYear = {};

  const nextMobileMainArrow = timeline.querySelector(".main-content-carousel__button-arrow-next");
  const prevMobileMainArrow = timeline.querySelector(".main-content-carousel__button-arrow-prev");

  const mainSwiper = new Swiper(mainSwiperContainer, {
    pagination: {
      el: timeline.querySelector(".timeline__mobile-swiper-pagination"),
      clickable: true,
      renderBullet: function (index, className) {
        const activeSlide = this.slides[index];
        const timelineItem = activeSlide.querySelector(".timeline-item");

        if (timelineItem) {
          const monthId = timelineItem.getAttribute('data-month');
          const year = timelineItem.getAttribute('data-year');
          const capitalizedMonth = capitalizeFirstLetter(monthId);

          // Initialize year in renderedMonthsByYear if not present
          if (!renderedMonthsByYear[year]) {
            renderedMonthsByYear[year] = new Set();
          }

          // Check if month is already rendered for the year
          if (renderedMonthsByYear[year].has(monthId)) {
            return `<span data-month="${monthId}" data-year="${year}" class="${className} custom-bullet hidden"></span>`; // Render hidden bullet
          } else {
            renderedMonthsByYear[year].add(monthId); // Mark month as rendered
            return `<span data-month="${monthId}" data-year="${year}" class="${className} custom-bullet text-4xl font font-medium text-teal-400 p-2 px-8 w-[300px] flex items-center">${capitalizedMonth}</span>`;
          }
        }
        return ""; // Default for slides without a timeline item
      }
    },
    navigation: {
      nextEl: nextMobileMainArrow,
      prevEl: prevMobileMainArrow,
    },
    loop: false,
    spaceBetween: 20,
    on: {
      beforeSlideChangeStart: function () {
        const activeSlide = mainSwiper.slides[mainSwiper.activeIndex];
        const buttons = activeSlide.querySelectorAll(".swiper-button-next, .swiper-button-prev");

        // hide buttons
        for (const button of buttons) {
          button.classList.add("hidden");
        }
      },
      slideChange: function () {
        const activeSlide = mainSwiper.slides[mainSwiper.activeIndex];
        const timelineItem = activeSlide.querySelector(".timeline-item");

        if (timelineItem) {
          const monthId = timelineItem.getAttribute('data-month');
          const year = timelineItem.getAttribute('data-year');
          highlightBullet(monthId, year);

          // force a repaint to fix the pagination arrows
          const buttons = timelineItem.querySelectorAll(".swiper-button-next, .swiper-button-prev");
          // show buttons
          for (const button of buttons) {
            button.classList.remove("hidden");

            // force a repaint to fix the pagination arrows
            button.style.display = "none";

            setTimeout(() => {
              button.offsetHeight;
              button.style.display = "";
            }, 100);
          }
        }

        updateArrowStates();
        updateYearBasedOnActiveSlide();
        centerActiveBullet();
        updateMobileArrowPositions();
      },
    },
  });


  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  // Function to highlight the bullet manually
  function highlightBullet(monthId, year) {
    const bullets = timeline.querySelectorAll(".timeline__mobile-swiper-pagination .custom-bullet");

    bullets.forEach((bullet) => {
      bullet.classList.remove("swiper-pagination-bullet-active"); // Reset active class

      if (bullet.dataset.month === monthId && bullet.dataset.year === year) {
        bullet.classList.add("swiper-pagination-bullet-active"); // Add active class only to the first unique bullet
      }
    });
  }

  function capitalizeFirstLetter(string ) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  // Initialize Swiper for image carousels inside sections
  const mobileImageSwipers = timeline.querySelectorAll(
    ".mobile-image-swiper"
  );

  mobileImageSwipers.forEach((mobileImageSwiper) => {
    new Swiper(mobileImageSwiper, {
      //effect: "fade",
      navigation: {
        nextEl: mobileImageSwiper.querySelector(".mobile-image-swiper__arrows .swiper-button-next"),
        prevEl: mobileImageSwiper.querySelector(".mobile-image-swiper__arrows .swiper-button-prev"),
      },
      pagination: {
        el: mobileImageSwiper.querySelector(".swiper-pagination"),
        clickable: true,
      },
      loop: false,
    });
  });

  function updateYearBasedOnActiveSlide() {
    const activeSlide =
      mainSwiper.slides[mainSwiper.activeIndex].querySelector(
        ".timeline-item"
      );
    if (activeSlide) {
      const activeYear = activeSlide.getAttribute('data-year');
      const yearIndex = years.indexOf(activeYear);
      if (yearIndex !== -1) {
        currentYearIndex = yearIndex;
        yearDisplay.textContent = years[currentYearIndex];
      }
    }
  }

  // Center the active bullet in the pagination
  function centerActiveBullet() {
    const activeBullet = timeline.querySelector(
      ".swiper-pagination-bullet-active"
    );

    if (activeBullet) {
      const paginationContainer = activeBullet.parentElement;
      const containerWidth = paginationContainer.offsetWidth;
      const bulletPosition =
        activeBullet.offsetLeft + activeBullet.offsetWidth / 2;
      const scrollPosition = bulletPosition - containerWidth / 2;

      paginationContainer.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });
    }
  }

  // Initial check to disable or enable arrows
  function updateArrowStates() {
    // Disable prev arrow if at the first year
    prevYearArrow.classList.toggle(
      "disabled-arrow",
      currentYearIndex === 0 && mainSwiper.isBeginning
    );
    // Disable next arrow if at the last year
    nextYearArrow.classList.toggle(
      "disabled-arrow",
      currentYearIndex === (years.length - 1)
    );
  }

  // Call updateArrowStates on page load to ensure correct initial state
  updateArrowStates();

  // Function to navigate to the most recent or first month slide of a specified year
  function navigateToYear(yearIndex, goToMostRecent = true) {
    const targetYear = years[yearIndex];
    const slides = Array.from(mainSwiper.slides);
    const yearSlides = slides
      .map((slide, index) => ({ index, slide }))
      .filter(({ slide }) =>
        slide.querySelector(".timeline-item").getAttribute('data-year') === targetYear
      );

    let targetSlideIndex;
    if (goToMostRecent) {
      // Get the last slide index for the most recent month of the year
      targetSlideIndex = yearSlides[yearSlides.length - 1].index;
    } else {
      // Get the first slide index for the earliest month of the year
      targetSlideIndex = yearSlides[0].index;
    }

    if (typeof targetSlideIndex === "number") {
      mainSwiper.slideTo(targetSlideIndex);
      yearDisplay.textContent = targetYear;
    }
  }

  const updateMobileArrowPositions = () => {
    const activeSlide = mainSwiper.slides[mainSwiper.activeIndex];
    if (!activeSlide) {
      return;
    }   
    
    // find height on the title element h2 in the slide and position arrows just above
    const title = activeSlide.querySelector("h2");
    if (!title) {
      return;
    }

    const arrowTop = title.offsetTop;
    nextMobileMainArrow.style.setProperty('--swiper-navigation-top-offset', `${arrowTop}px`);
    prevMobileMainArrow.style.setProperty('--swiper-navigation-top-offset', `${arrowTop}px`);
  }

  // Event listener for the previous year arrow
  prevYearArrow.addEventListener("click", () => {
    if (currentYearIndex > 0) {
      // Otherwise, go to the most recent slide of the previous year
      console.log("hit two");
      currentYearIndex -= 1;
    }

    navigateToYear(currentYearIndex, false); // Pass true to navigate to the most recent month
    centerActiveBullet();
    updateArrowStates();
  });

  // Event listener for the next year arrow
  nextYearArrow.addEventListener("click", () => {
    if (currentYearIndex < years.length - 1) {
      // Go directly to the first slide of the next year
      currentYearIndex += 1;
      navigateToYear(currentYearIndex, false);
      centerActiveBullet();
    }

    updateArrowStates();
  });

  // add event listener for when the content in the first slide is loaded
  if (!mainSwiper && !mainSwiper.slides[0]) {
    const firstSlideImage = mainSwiper.slides[0].querySelector("img, iframe, video");
    if (firstSlideImage) {
      if (firstSlideImage.complete) {
        updateMobileArrowPositions();
      } else {
        firstSlideImage.addEventListener("load", () => {
          updateMobileArrowPositions();
        });
      }
    }
  }

  window.addEventListener("resize", () => {
    updateArrowStates();
  });
});